<template>
<div>
    <!-- Row Start -->
    <div class="row">
        <div class="col-12">
            <div class="card card-custom gutter-b">
                <div class="card-header">
                    <div class="card-title">
                        <h3 class="card-label">GST / VAT</h3>
                    </div>
                </div>
                <div class="card-body body-fluid">
                    <div class="row">
                        <div class="col-4">
                            <b-form-group label="Truck VAT" label-for="name-input">
                                <b-form-input v-model="form.truck_vat"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-4">
                            <b-form-group label="Air VAT" label-for="name-input">
                                <b-form-input v-model="form.air_vat"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-4">
                            <b-form-group label="Sea VAT" label-for="name-input">
                                <b-form-input v-model="form.sea_vat"></b-form-input>
                            </b-form-group>
                        </div>
                        
                        <div class="col-12">
                            <b-button @click="updateVat()" class="ml-2 float-right" variant="primary">Update</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Row End -->

        <loading :active.sync="isLoading"></loading>
    </div>
</div>
</template>

<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
    data() {
        return {
            vatValues: [],
            isLoading: false,
            errors: [],
            fields: ["name", "actions"],
            form: {
                id: 0,
                truck_vat: null,
                air_vat: null,
                sea_vat: null,
            },
        };
    },
    components: {
        Loading,
    },
    created() {
        this.getVatValues();
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
                title: "Settings",
            },
            {
                title: "GST / Vat",
            },
        ]);
    },
    methods: {
        checkErrors() {
            if (this.form.air_vat && this.form.truck_vat && this.form.sea_vat) {
                return true;
            }

            this.errors = [];

            if (!this.form.truck_vat) {
                this.errors.push("Trcuk Vat is required.");
            }
            if (!this.form.air_vat) {
                this.errors.push("Air Vat is required.");
            }
            if (!this.form.sea_vat) {
                this.errors.push("Sea Vat is required.");
            }

            for (let field of this.errors) {
                this.makeToastVariant("danger", field, "Error");
            }
        },
        resetModal() {
            this.form.name = null;
        },
        updateVat() {
            if (this.checkErrors() == true) {
                this.isLoading = true;
                debugger;
                ApiService.post("gstvat", this.form)
                    .then(() => {
                        this.getVatValues();
                        this.$nextTick(() => {
                            this.makeToastVariant(
                                "success",
                                "Vat Successfully added successfully",
                                "Success"
                            );
                        });
                    })
                    .catch(({
                        response
                    }) => {
                        this.isLoading = false;
                        for (let field of Object.keys(response.data.error)) {
                            this.makeToastVariant(
                                "danger",
                                response.data.error[field][0],
                                "Error"
                            );
                        }
                    });
            }
        },
        makeToastVariant(variant = null, text, title) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        },

        getVatValues() {
            this.isLoading = true;
            ApiService.get("gstvat")
                .then(({
                    data
                }) => {
                    this.form.truck_vat = data.truck_vat;
                    this.form.air_vat = data.air_vat;
                    this.form.sea_vat = data.sea_vat;
                    this.form.id = data.id;
                    this.isLoading = false;
                })
                .catch(() => {});
        }
    },
};
</script>
